import Box from '@mui/material/Box';
import EventBus from 'eventing-bus';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Checkbox from '@mui/material/Checkbox';
import { Modal, ModalBody } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import StepLabel from '@mui/material/StepLabel';
import React, { useEffect, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import './index.css';
import 'animate.css';
import '../../static/css/animate.css';
import Sidebar from '../../components/sidebar';
import NavbarInner from '../../components/navbar-inner';
import FieldMapping from '../../components/field-mapping';
import FieldObjects from '../../components/field-objects';
import { getMigration, connectPlatform, nextFieldMapping, setupSample, getFieldsMapping, setSupportedObjects, setModalObj } from '../../store/actions/Migration';

const NewMigration = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const [activeStep, setActiveStep] = useState(0);
    const [paymentModal, setPaymentModal] = useState(false);
    const { migration, sfAuth, hsAuth, supportedObjects, objectsFieldMapping, modalObj } = useSelector(st => st.Migration);

    const steps = ['Connect data sources', 'Select Objects', 'Configure data mapping', 'Checkout'];
    useEffect(() => {
        const split = location['pathname'].split('/');
        const type = split[split.length - 1];
        const urlParams = new URLSearchParams(window['location']['search']);
        if (type == 'NewMigration') dispatch(getMigration({ key: urlParams.get('key'), type: 'none' }));
        else if (type == 'hubspotAuth') {
            const hubspotCode = urlParams.get('code');
            const hubspotState = urlParams.get('state');
            dispatch(connectPlatform({ platform: 'Hubspot', key: hubspotState, code: hubspotCode }));
        } else if (type == 'salesforceAuth') {
            const salesforceCode = urlParams.get('code');
            const salesforceState = urlParams.get('state');
            dispatch(connectPlatform({ platform: 'Salesforce', key: salesforceState, code: salesforceCode }));
        }
    }, []);

    useEffect(() => setEnvoinrment(), []);
    useEffect(() => setEnvoinrment(), [migration]);
    const setEnvoinrment = () => {
        if (migration['status'] === 'connected') setActiveStep(1);
        else if (migration['status'] === 'field-mapping') setActiveStep(3);
        if (migration['supportedObjects']) dispatch(setSupportedObjects(migration['supportedObjects']));
        if (migration['key'] && !['created'].includes(migration['status']) && activeStep != 1 && !objectsFieldMapping['providerFields']) dispatch(getFieldsMapping(migration['key']));
    };

    const handleNext = () => {
        // Make sure both Salesforce and Hubspot is connected
        if (activeStep == 0 && (!migration['hubspotUserId'] || !migration['sourceUserId']))
            return EventBus.publish('error', 'Please connect HubSpot and Salesforce!');
        if (activeStep == 1 && supportedObjects.length == 0)
            return EventBus.publish('error', 'Please select objects to migrate!');
        if (activeStep == 1 && !supportedObjects.find(key => key.indexOf('_HardData') >= 0))
            return EventBus.publish('error', 'Please select a Hard data object ');
        if (activeStep == 2 && migration['status'] == 'connected')
            return dispatch(nextFieldMapping(migration['key']));
        setActiveStep(activeStep + 1);
    };

    return (
        <div className="dashboard-page">
            <Sidebar />
            <VisibilitySensor>
                <div className="content">
                    <NavbarInner />
                    <div className='dashboard-area'>
                        <div className='title-area'> <h2>{migration['companyName']} - Setup</h2> </div>
                        <div className='content-box'>
                            <Box sx={{ width: '100%' }}>
                                <Stepper activeStep={activeStep} className='create-banner-step'>
                                    {steps.map(label => <Step key={label}> <StepLabel>{label}</StepLabel> </Step>)}
                                </Stepper>
                                {activeStep === 0 && (
                                    <div className='connect-inner'>
                                        <div className='connect-box'>
                                            <div className='left-area'>
                                                <i className='icon'><img src={require('../../static/images/hubspot.jpg')} alt='' /></i>
                                                <h4>HubSpot <span>|</span>
                                                    {migration?.['hubspotPortalId']
                                                        ? <span>Connected to Hub ID: <strong>{migration['hubspotPortalId']}</strong></span>
                                                        : <span>Not connected yet</span>
                                                    }
                                                </h4>
                                            </div>
                                            <div className='right-area'>
                                                {migration?.['hubspotPortalId']
                                                    ? <a className='btn-style-one' style={{ "cursor": 'default', 'fontSize': '14px' }}>Connected</a>
                                                    : <a className='btn-style-two' href={hsAuth}>Connect your account</a>
                                                }
                                            </div>
                                        </div>
                                        <div className='connect-box'>
                                            <div className='left-area'>
                                                <i className='icon'><img src={require('../../static/images/salesforce.jpg')} alt='' /></i>
                                                <h4>Salesforce <span>|</span>
                                                    {migration?.['sourceUserId']
                                                        ? <span>Connected to Account ID: <strong>{migration?.['sourceAccountId']}</strong></span>
                                                        : <span>Not connected yet</span>
                                                    }
                                                </h4>
                                            </div>
                                            <div className='right-area'>
                                                {migration?.['sourceUserId']
                                                    ? <a className='btn-style-one' style={{ "cursor": 'default', 'fontSize': '14px' }}>Connected</a>
                                                    : <a className='btn-style-two' href={sfAuth}>Connect your account</a>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activeStep === 1 && <FieldObjects />}
                                {activeStep === 2 && <FieldMapping />}
                                {activeStep === 3 && (
                                    <div className='connect-inner'>
                                        <div className='title-area overview-title'>
                                            <h5>Overview</h5>
                                            <button className='btn-style-two' onClick={() => setActiveStep(2)}>Edit field mapping <i className='fa fa-edit fa-lg' /></button>
                                        </div>
                                        <div className='table-area checkout-table'>
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th>From</th>
                                                            <th>To</th>
                                                            <th>Total Records</th>
                                                            <th>Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className='company-data'>
                                                                    <i className='icon'><img src={require('../../static/images/salesforce.jpg')} alt='' /></i>
                                                                    <h4>Salesforce</h4>
                                                                </div>
                                                                <p style={{ color: '#007bff' }}>{migration?.['sourceAccountId']}</p>
                                                            </td>
                                                            <td>
                                                                <div className='company-data'>
                                                                    <i className='icon'><img src={require('../../static/images/hubspot.jpg')} alt='' /></i>
                                                                    <h4>HubSpot</h4>
                                                                </div>
                                                                <p style={{ color: '#007bff' }}>{migration?.['hubspotPortalId']}</p>
                                                            </td>
                                                            <td>{migration['totalRecords']}</td>
                                                            <td>3,500 USD</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, alignItems: 'center' }}>
                                    {activeStep != 0 &&
                                        <Button className='btn-style-one' onClick={() => setActiveStep(activeStep - 1)}> <i className='fa fa-arrow-left mr-1' /> Back </Button>
                                    }
                                    <div className='ml-auto'>
                                        {activeStep == 3 ?
                                            <>
                                                {migration['status'] == 'sample' ?
                                                    <p style={{ color: '#007bff' }}> Running Sample </p>
                                                    : <>
                                                        <button className='migrat-btn ml-auto' color="inherit" sx={{ mr: 1 }}>
                                                            <i className='icon'><img src={require('../../static/images/info-icon.png')} alt='' /></i>Migrate a sample
                                                        </button>
                                                        <Button className='btn-style-one' onClick={() => dispatch(setupSample(migration['key']))}> Free sample migration </Button>
                                                    </>
                                                }
                                            </>
                                            :
                                            <Button className='btn-style-one' onClick={handleNext}>
                                                Next <i className='fa fa-arrow-right ml-1' />
                                            </Button>
                                        }
                                    </div>
                                </Box>
                            </Box>
                        </div>
                    </div>
                </div >
            </VisibilitySensor>
            {/* ------------------Migration Detail MODAL----------------- */}
            <Modal
                isOpen={paymentModal}
                className='modal-payment'
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <ModalBody>
                    <div className='payment-box'>
                        <Tabs>
                            <TabList>
                                <Tab>
                                    <i className='icon'><img src={require('../../static/images/card.png')} alt='' /></i>
                                    Card
                                </Tab>
                                <Tab>
                                    <i className='icon'><img src={require('../../static/images/bank.png')} alt='' /></i>
                                    ACH bank deposit
                                </Tab>
                                <Tab>
                                    <i className='icon'><img src={require('../../static/images/dots.png')} alt='' /></i>
                                </Tab>
                            </TabList>
                            <TabPanel>
                                <div className='card-box'>
                                    <div className='group-form'>
                                        <label>Card number</label>
                                        <input type='number' placeholder='1234 1234 1234 1234' />
                                        <i className='icon'><img src={require('../../static/images/cards-credit.png')} alt='' /></i>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className='group-form'>
                                                <label>Expiration date</label>
                                                <input type='date' placeholder='MM/YY' />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className='group-form'>
                                                <label>Security code</label>
                                                <input type='number' placeholder='CVV' />
                                                <i className='icon'><img src={require('../../static/images/disbled-card.png')} alt='' /></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='group-form'>
                                        <button className='btn-style-three'>Pay USD175.00</button>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel></TabPanel>
                            <TabPanel></TabPanel>
                        </Tabs>
                    </div>
                </ModalBody>
            </Modal>


            {/* ------------------Restart Object----------------- */}
            <Modal
                centered
                className='modal-payment'
                isOpen={modalObj['title']}
                aria-labelledby='contained-modal-title-vcenter'
            >
                <ModalBody>
                    <div className='payment-box'>
                        <div className='title-area text-center'> <h5>{modalObj['title']}</h5> </div>
                        <p className='pt-2' style={{ 'fontSize': '17px' }}> {modalObj['body']} </p>
                        <div className='text-center pt-3'>
                            <button className='btn-style-one' onClick={() => dispatch(setModalObj({}))}> Ok </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div >
    );
}


export default NewMigration;