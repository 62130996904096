import EventBus from 'eventing-bus';
import Checkbox from '@mui/material/Checkbox';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSupportedObjects, setSelectedObjects } from '../../store/actions/Migration';

const FieldObjects = () => {
    const dispatch = useDispatch();
    const { migration, supportedObjects } = useSelector(st => st.Migration);

    useEffect(() => setEnvironment(), []);
    // useEffect(() => setEnvironment(), [migration]);
    const setEnvironment = () => { if (migration['supportedObjects']) dispatch(setSupportedObjects(migration['supportedObjects'])) };

    const selectObject = (key) => {
        let newSupportObj = supportedObjects;
        if (!supportedObjects.includes(key)) {
            newSupportObj = newSupportObj.concat(key);
            dispatch(setSupportedObjects(newSupportObj));
        } else {
            newSupportObj = newSupportObj.filter(x => x !== key);
            dispatch(setSupportedObjects([...newSupportObj]));
        }
        dispatch(setSelectedObjects({ supportedObjects: JSON.stringify(newSupportObj), key: migration['key'] }));
    }

    return (
        <div className='connect-inner object-tab'>
            <div className='title-area'>
                <p>Select the <b>standard objects</b> you want to migrate</p>
            </div>
            <div className='checkbox-box'>
                <h4>HARD DATA</h4>
                <div className='checkbox-area'>
                    {hardObjects.map(({ label, key }) => <div className='label-box'> <Checkbox checked={supportedObjects.includes(key)} onClick={() => selectObject(key)} /> <label> {label}</label> </div>)}
                </div>
            </div>
            <div className='checkbox-box style-two'>
                <h4>SOFT DATA</h4>
                <div className='checkbox-area'>
                    {softObjects.map(({ label, key }) =>
                        <div className='label-box'>
                           <Checkbox checked={supportedObjects.includes(key)} onClick={() => selectObject(key)} />
                            <label> {label}</label>
                        </div>
                    )}
                </div>
            </div>
            {/* <div className='title-area style-two'>
                <p>Select the <b>custom objects</b> you want to migrate</p>
            </div>
            <div className='checkbox-box'>
                <div className='checkbox-area'>
                    <div className='label-box'> <Checkbox /> <label> Custom Object 1</label> </div>
                    <div className='label-box'> <Checkbox /> <label> Custom Object 2</label> </div>
                    <div className='label-box'> <Checkbox /> <label> Custom Object 3</label> </div>
                    <div className='label-box'> <Checkbox /> <label> Custom Object 4</label> </div>
                </div>
            </div> */}
        </div>
    );
};

const hardObjects = [{ label: 'Opportunities', key: 'Opportunities_HardData' }, { label: 'Leads', key: 'Leads_HardData' }, { label: 'Contacts', key: 'Contacts_HardData' }, { label: 'Accounts', key: 'Accounts_HardData' }]
const softObjects = [{ label: 'Notes', key: 'Notes_SoftData' }, { label: 'Tasks', key: 'Tasks_SoftData' }, { label: 'Calls', key: 'Calls_SoftData' }, { label: 'Events', key: 'Events_SoftData' }, { label: 'Emails', key: 'Email-Message_SoftData' }, { label: 'Attachments', key: 'Attachments_SoftData' }];

export default FieldObjects;