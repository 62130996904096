import _ from 'lodash';
import EventBus from 'eventing-bus';
import Paper from "@material-ui/core/Paper";
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { addHubspotProperty } from '../../store/actions/Migration';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const AddProperty = (props) => {
    const dispatch = useDispatch();
    const { migration } = useSelector(st => st.Migration);

    const [isPicklist, setIsPicklist] = useState(false);
    const [picklistOptions, setPicklistOptions] = useState([]);
    const [picklistIteration, setPicklistIteration] = useState(0);
    const [formData, setFormData] = useState({ label: '', internalValue: '', description: '', fieldTypeLabel: '', fieldType: '', actionType: '', providerName: 'salesforce' });

    useEffect(() => {
        let { editField, action } = props;
        if (action == 'edit') {
            let type = propertyType.find(({ value }) => value == editField['hubspotFieldType']);
            setFormData({ ...formData, label: editField['hubspotLabel'], internalValue: editField['hubspotName'], description: editField['description'], fieldType: editField['hubspotFieldType'], fieldTypeLabel: type['label'] });
            selectFieldType(type, editField['hubspotOptions'], 'auto');
        }
    }, [props]);

    const onChangeForm = (e) => {
        if (e.target['name'] == 'label')
            setFormData({ ...formData, [e.target['name']]: e.target['value'], 'internalValue': e.target['value'] ? `${e.target['value'].replaceAll(' ', '_').toLowerCase()}_migratr` : '' })
        else setFormData({ ...formData, [e.target['name']]: e.target['value'] })
    }

    const selectFieldType = (type, options, action) => {
        if (action == 'manual') {
            if (!type) setFormData({ ...formData, fieldType: '', fieldTypeLabel: '' });
            else setFormData({ ...formData, fieldType: type['value'], fieldTypeLabel: type['label'] });
        }
        if (['booleancheckbox', 'radio', 'select'].includes(type?.['value'])) {
            setIsPicklist(true);
            if (options) {
                let newOptions = [];
                options.map((option) => {
                    newOptions.push({ ...option, id: picklistIteration });
                    setPicklistIteration(picklistIteration + 1);
                });
                setPicklistOptions(newOptions);
            } else {
                setPicklistOptions([{ id: 1 }]);
                setPicklistIteration(picklistIteration + 1);
            }
        } else {
            setIsPicklist(false);
            setPicklistOptions([]);
        }
    }

    const onChangePicklist = (e, index) => setPicklistOptions(prev => prev.map((el, i) => i !== index ? el : {...el, [e['target']['id']]: e['target']['value']}));

    const dropdownOptions = (action, id) => {
        if (action == 'add') {
            setPicklistIteration(picklistIteration + 1);
            setPicklistOptions([...picklistOptions, { id: picklistIteration + 1 }])
        } else if (action == 'remove') {
            let newPicklist = [...picklistOptions];
            newPicklist.splice(id, 1);
            setPicklistOptions(newPicklist);
        }
    }

    const submitNewProperty = () => {
        if (!formData['label']) return EventBus.publish('error', 'Please provide property label!');
        if (!formData['internalValue']) return EventBus.publish('error', 'Please provide property internal name!');
        dispatch(addHubspotProperty({
            ...formData,
            sfObject: props?.['source'],
            objectType: props?.['salesforceProp']?.['objectType'] || props?.['editField']?.['objectType'],
            salesforceProp: props['salesforceProp'], key: migration['key'], picklistOptions,
            actionType: props['action'], editField: props['editField']
        }));
        props.setNewModal('');
    }

    const populateOption = () => {
        console.log('********* populateOption = ', props?.['salesforceProp']);
        let options = [...picklistOptions];
        if (props?.['salesforceProp']?.['picklistValues']) {
            props?.['salesforceProp']?.['picklistValues'].map(({ label, value }) => {
                if (!options.find(option => option['value'] == value)) options.push({ id: picklistIteration, label, value });
            });
            setPicklistOptions(options);
        } else return EventBus.publish('error', 'No picklist option found!');
    }

    return (
        <div className='connect-inner'>
            <div className='payment-box'>
                <div className='title-area text-center mb-4'> <h2>{_.capitalize(props['action'])} HubSpot Property</h2> </div>
                <ValidatorForm className="row" onSubmit={submitNewProperty} >
                    <div className='group-form col-lg-6 col-md-12'>
                        <label>Label</label>
                        <CustomTextField
                            fullWidth
                            type='text'
                            name='label'
                            margin='dense'
                            autoComplete='off'
                            onChange={onChangeForm}
                            value={formData['label']}
                            validators={['required']}
                            placeholder='Provide field label'
                            errorMessages={['Label cannot be empty']}
                        />
                    </div>
                    <div className='group-form col-lg-6 col-md-12'>
                        <label>Internal Name</label>
                        <CustomTextField
                            fullWidth
                            type='text'
                            margin='dense'
                            autoComplete='off'
                            name='internalValue'
                            onChange={onChangeForm}
                            validators={['required']}
                            value={formData['internalValue']}
                            placeholder='Provide field internal name'
                            errorMessages={['Name cannot be empty']}
                        />
                    </div>
                    <div className='group-form col-12'>
                        <label>Description</label>
                        <CustomTextField
                            fullWidth
                            type='text'
                            margin='dense'
                            name='description'
                            autoComplete='off'
                            onChange={onChangeForm}
                            value={formData['description']}
                            placeholder='Provide field description'
                        />
                    </div>
                    <div className='group-form col-12'>
                        <label>Property Type</label>
                        <Autocomplete
                            id='fieldTypeLabel'
                            sx={{ width: 300 }}
                            options={propertyType}
                            value={formData['fieldTypeLabel']}
                            renderInput={(params) => <TextField {...params} />}
                            menuStyle={{ fontSize: 14 }} listStyle={{ fontSize: 14 }}
                            onChange={(event, property) => selectFieldType(property, null, 'manual')}
                            getOptionDisabled = {option => {
                                let {sourceTypes} = props;
                                let sfField = props['salesforceProp'];
                                if(sfField && sourceTypes[sfField['type']] && !sourceTypes[sfField['type']].find(({ type }) => type == option['type'])) return true;
                                else return false;
                            }}
                            PaperComponent={({ children }) => <Paper menuStyle={{ fontSize: 14 }} listStyle={{ fontSize: 14 }} style={{ fontSize: '14px' }}>{children}</Paper>}
                        />
                    </div>
                    <div className='option-box justify-content-center'>
                        <button className='save-btn' type='submit'>Save</button>
                    </div>
                </ValidatorForm>

                {isPicklist &&
                    <div className="new-option active">
                        <div className='title-area text-center mb-2 mt-4'>
                            <h2>New Option</h2>
                        </div>
                        <div className='option-box mb-2 justify-content-center'>
                            <div className='add-option'>
                                <button onClick={() => dropdownOptions('add')}>+ Add new option</button>
                                <button onClick={populateOption}><i className='icon'><img src={require('../../static/images/add-icon.png')} alt='' /></i> Populate options</button>
                            </div>
                        </div>
                        <div className='row'>
                            {picklistOptions.map((picklistOption, i) =>
                                <>
                                    <div className='group-form col-lg-6 col-md-6'>
                                        <label>Label</label>
                                        <input type='text' id='label' name={`label`} placeholder='' value={picklistOption['label']} onChange={(e) => onChangePicklist(e, i)} />
                                    </div>
                                    <div className='group-form col-lg-5 col-md-5'>
                                        <label>Internal Value</label>
                                        <input type='text' id='value' name={`value`} placeholder='' value={picklistOption['value']} onChange={(e) => onChangePicklist(e, i)} />
                                    </div>
                                    <div className='group-form col-lg-1 col-md-1'>
                                        <button onClick={() => dropdownOptions('remove', i)}><img src={require('../../static/images/trash.png')} alt='' /></button>
                                    </div>
                                </>
                            )}
                        </div >
                    </div>
                }
            </div>
        </div>
    );
};

// Hubspot Property Types
const propertyType = [
    { label: 'Single-line text', value: 'text', type: 'string' },
    { label: 'Multi-line text', value: 'textarea', type: 'string'  },
    { label: 'Dropdown', value: 'select', type: 'enumeration'  },
    { label: 'Number', value: 'number', type: 'number'  },
    { label: 'Single checkbox', value: 'booleancheckbox', type: 'bool'  },
    { label: 'Multiple checkbox', value: 'checkbox', type: 'enumeration'  },
    { label: 'Radio select', value: 'radio', type: 'enumeration'  },
    { label: 'Date picker', value: 'date', type: 'date'  },
    // { label: 'Reference', value: 'reference', type: 'string'  },
];

const CustomTextField = withStyles({
    root: { '& .MuiInputBase-input': {
            color: '#fff', // Text color
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#fff', // Semi-transparent underline
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: '#fff', // Solid underline on hover
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#fa6634', // Solid underline on focus
        },
    },
    input: {
        '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
        }
    }
})(TextValidator);

export default AddProperty;