import { connect } from 'react-redux';
import React, { Component } from 'react';

import './index.css';

class Preloader extends Component {

  render() {
    return (
      <div className="loader">
        <div class="pl">
          <div class="pl__bars">
            <div class="pl__bar">
              <div class="pl__bar-s"></div>
              <div class="pl__bar-t"></div>
              <div class="pl__bar-l"></div>
              <div class="pl__bar-r"></div>
            </div>
            <div class="pl__bar">
              <div class="pl__bar-s"></div>
              <div class="pl__bar-t"></div>
              <div class="pl__bar-l"></div>
              <div class="pl__bar-r"></div>
            </div>
            <div class="pl__bar">
              <div class="pl__bar-s"></div>
              <div class="pl__bar-t"></div>
              <div class="pl__bar-l"></div>
              <div class="pl__bar-r"></div>
            </div>
            <div class="pl__bar">
              <div class="pl__bar-s"></div>
              <div class="pl__bar-t"></div>
              <div class="pl__bar-l"></div>
              <div class="pl__bar-r"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = ({ Auth }) => {
  let { } = Auth;
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Preloader);
