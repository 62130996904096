import Papa from 'papaparse';
import EventBus from 'eventing-bus';
import { CSVLink } from 'react-csv';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import Select from '@mui/material/Select';
import capitalize from 'lodash.capitalize';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import React, { act, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import AddProperty from './addProperty';
import { setSelectedObjects, downloadCSV, setDownloadCSV, uploadFields, saveNewMapping, removeField, setSupportedObjects, updateErrorPopup, getFieldsMapping } from '../../store/actions/Migration';

const FieldMapping = () => {
    const dispatch = useDispatch();
    const { migration, downloadFields, supportedObjects, objectsFieldMapping, errorMessage } = useSelector(st => st['Migration']);

    const [action, setAction] = useState('');
    const [source, setSource] = useState('');
    const [newModal, setNewModal] = useState('');
    const [editField, setEditField] = useState({});
    const [hubspotProp, setHubspotProp] = useState({});
    const [newProperty, setNewProperty] = useState({});
    const [openDropdown, setOpenDropdown] = useState(false);
    const [salesforceProp, setSalesforceProp] = useState({});

    useEffect(() => {
        if (!objectsFieldMapping['providerFields']) dispatch(getFieldsMapping(migration['key']));
        if (Object.keys(supportedObjects).length == 0) dispatch(setSupportedObjects(migration['supportedObjects']));
    }, []);

    useEffect(() => {
        if (downloadFields) {
            document.getElementById('Fields-CSV').click();
            setTimeout(() => dispatch(setDownloadCSV('')), 500);
        }
    }, [downloadFields]);

    const objectsDropdownChange = (e) => {
        let newSupportObj = supportedObjects;
        if (!supportedObjects.includes(e['target']['value'][0])) {
            newSupportObj = newSupportObj.concat(e['target']['value']);
            dispatch(setSupportedObjects([...supportedObjects, ...e['target']['value']]));
        } else {
            newSupportObj = newSupportObj.filter(x => x !== e['target']['value'][0]);
            dispatch(setSupportedObjects([...newSupportObj]));
        }
        dispatch(setSelectedObjects({ supportedObjects: JSON.stringify(newSupportObj), key: migration['key'] }));
    };

    const openNewHubProperty = (property, objectType, source) => {
        if (!salesforceProp?.[objectType]) return EventBus.publish('error', 'Please select Salesforce Property First!');

        if (property && property['label'] == 'Add New') {
            setAction('add');
            setSource(source);
            setNewModal(objectType);
        } else setHubspotProp({ ...hubspotProp, [objectType]: property });
    };

    const clickUploadFile = (objectType) => {
        document.getElementById(`mapping-${objectType}`)['value'] = '';
        document.getElementById(`mapping-${objectType}`).click();
    };
    const handleFileUpload = (e) => {
        if (e['target']['files'].length) {
            const file = e.target.files[0];
            const fileExtension = file?.type.split('/')[1];
            if (fileExtension != 'csv') return EventBus.publish('error', 'Please input a csv file');

            const reader = new FileReader();
            reader.onload = async ({ target }) => {
                const objectType = e.target.id.split('-')[1];
                const { data: csvData } = Papa.parse(target['result'], { header: true });
                dispatch(uploadFields({ objectType, key: migration['key'], csvData: JSON.stringify(csvData) }));
            };
            reader.readAsText(file);
        }
        e['target']['value'] = '';
    };

    const saveNewField = (objectType, sfObject) => {
        if (!hubspotProp?.[objectType]) return EventBus.publish('error', 'Please select HubSpot Property First!');
        if (!salesforceProp?.[objectType]) return EventBus.publish('error', 'Please select Salesforce Property First!');

        dispatch(saveNewMapping({ objectType, migrationKey: migration['key'], hubspotProp: hubspotProp[objectType], sourceProp: salesforceProp[objectType], sfObject }));

        setHubspotProp({ ...hubspotProp, [objectType]: {} });
        setSalesforceProp({ ...salesforceProp, [objectType]: {} });
    }

    let sourceTypes = {
        'boolean': [{ type: 'bool', fieldType: 'booleancheckbox' }],

        'int': [{ type: 'number', fieldType: 'number' }, { type: 'string', fieldType: 'text' }],
        'long': [{ type: 'number', fieldType: 'number' }, { type: 'string', fieldType: 'text' }],
        'double': [{ type: 'number', fieldType: 'number' }, { type: 'string', fieldType: 'text' }],
        'percent': [{ type: 'number', fieldType: 'number' }, { type: 'string', fieldType: 'text' }],
        'currency': [{ type: 'number', fieldType: 'number' }, { type: 'string', fieldType: 'text' }],

        'time': [{ type: 'datetime', fieldType: 'date' }, { type: 'string', fieldType: 'text' }, { type: 'date', fieldType: 'date' }],
        'date': [{ type: 'date', fieldType: 'date' }, { type: 'string', fieldType: 'text' }],
        'datetime': [{ type: 'datetime', fieldType: 'date' }, { type: 'string', fieldType: 'text' }, { type: 'date', fieldType: 'date' }],

        'id': [{ type: 'string', fieldType: 'text' }],
        'string': [{ type: 'string', fieldType: 'text' }, { type: 'string', fieldType: 'textarea' }],
        'textarea': [{ type: 'string', fieldType: 'textarea' }, { type: 'string', fieldType: 'text' }],

        'reference': [{ type: 'string', fieldType: 'text' }, { type: 'string', fieldType: 'textarea' }], // cannot be mapped to string || we can SKIP this || Grey them and show some text
        'picklist': [{ type: 'enumeration', fieldType: 'select' }, { type: 'enumeration', fieldType: 'booleancheckbox' }, { type: 'string', fieldType: 'text' }, { type: 'string', fieldType: 'textarea' }],
        'multipicklist': [{ type: 'enumeration', fieldType: 'select' }, { type: 'enumeration', fieldType: 'booleancheckbox' }, { type: 'string', fieldType: 'text' }, { type: 'string', fieldType: 'textarea' }],
    }

    return (
        <div className='connect-inner'>
            <div className='title-area'>
                <p className='text-simple'>
                    Field mappings are the link between Salesforce properties and HubSpot properties. <br />
                    If a field or property isn't mapped below, its data won't be included in the migration. <br />
                    Field mappings can be set up one by one or in bulk by CSV.
                </p>
                {migration['paymentStatus'] != 'paid' && <div className='select-area'>
                    <label id='supported-objects-multiple-checkbox-label' onClick={() => setOpenDropdown(!openDropdown)} style={{ marginRight: '0px', fontWeight: 'bold' }}>
                        {supportedObjects.length > 0 ? `Selected Objects to Migrate - ${supportedObjects.length}` : `Select Objects`}
                    </label>
                    <Select
                        name='supportedObjects'
                        id='supported-objects-multiple-checkbox'
                        labelId='supported-objects-multiple-checkbox-label'
                        value={[]} multiple onChange={objectsDropdownChange}
                        open={openDropdown} onClose={() => setOpenDropdown(false)} onOpen={() => setOpenDropdown(true)}
                    >
                        {objects.map(({ label, key }) => <MenuItem value={key}> <Checkbox checked={supportedObjects.includes(key)} /> {label} </MenuItem>)}
                    </Select>
                </div>}
            </div>
            {downloadFields && <CSVLink id='Fields-CSV' data={downloadFields['csvDate']} filename={downloadFields['filename']}></CSVLink>}
            {[
                { objectType: 'Deal', plural: 'deals', sources: 'Opportunities', source: 'Opportunity' },
                { objectType: 'Contact', plural: 'contacts', sources: 'Contacts', source: 'Contact' },
                { objectType: 'Contact', plural: 'contacts', sources: 'Leads', source: 'Lead' },
                { objectType: 'Company', plural: 'companies', sources: 'Accounts', source: 'Account' },
            ].map(({ objectType, plural, source, sources }) => {

                let objectFieldsMapping = objectsFieldMapping?.[source.toLocaleLowerCase()];
                let sourceLabel = objectFieldsMapping?.['providerFields'];
                let hubspotLabel = objectFieldsMapping?.['hubspotProperties'];

                let found = hubspotLabel?.find(({ label }) => label == 'Add New');
                if (!found) hubspotLabel?.push({ label: 'Add New' });
                if (supportedObjects.find(x => x.indexOf(sources) >= 0))
                    return (
                        <div className='table-area'>
                            <div className='add-property'>
                                <p className='acounts'>From <Link to='#'>{sources}</Link> to <Link to='#'>{capitalize(plural)}</Link></p>
                                <div className='right-area'>
                                    <button className='btn-style-two' onClick={() => setNewProperty({ ...newProperty, [source]: newProperty[source] ? false : true })}><i class='fa fa-plus fa-lg mr-2' style={{ color: 'grey' }} />Add property</button>
                                    <button className='btn-style-two' onClick={() => dispatch(downloadCSV({ key: migration['key'], objectType: source }))}><i class='fa fa-download fa-lg mr-2' />Download CSV</button>
                                    <button className='btn-style-two' onClick={() => clickUploadFile(source)}>
                                        <i class='fa fa-upload fa-lg mr-2' />  Upload CSV
                                        <input id={`mapping-${source}`} accept='.csv' hidden type='file' enctype='multipart/form-data' onChange={handleFileUpload} />
                                    </button>
                                </div>
                            </div>
                            <div class='table-responsive'>
                                <table class='table'>
                                    <thead>
                                        <tr>
                                            <th>
                                                Salesforce property
                                                <i className='icon-info'><img src={require('../../static/images/info-icon.png')} alt='' />
                                                    <div className='overlabox-tooltip'> <p>This is Salesforce Property Label. </p> </div>
                                                </i>
                                            </th>
                                            <th>
                                                HubSpot property
                                                <i className='icon-info'><img src={require('../../static/images/info-icon.png')} alt='' />
                                                    <div className='overlabox-tooltip'> <p>This is HubSpot Property Label. </p> </div>
                                                </i>
                                            </th>
                                            <th scope='col'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {newProperty?.[source] &&
                                            <tr>
                                                <td>
                                                    <Autocomplete
                                                        disablePortal
                                                        sx={{ width: 300 }}
                                                        options={sourceLabel}
                                                        id={`sourceLabel_${objectType}`}
                                                        getOptionLabel={option => `${option['label']} ${option['name']} (${option['type']})`}
                                                        getOptionDisabled={option => option['type'] == 'reference' ? true : false}
                                                        renderOption={(props, option) => {
                                                            const { key, ...optionProps } = props;
                                                            return (
                                                                <Box key={key} component='li' {...optionProps} style={{ 'fontSize': '14px' }}>
                                                                    <span>
                                                                        {option['label']}
                                                                        &nbsp; - &nbsp;
                                                                        <span style={{ 'color': '#5C5C5C' }}>
                                                                            {option['name']} ({option['type']})
                                                                            {option['type'] == 'reference' && <span style={{ 'fontSize': '12px' }}><br />Unsupported field type.</span>}
                                                                        </span>
                                                                    </span>
                                                                </Box>
                                                            );
                                                        }}
                                                        renderInput={params => <TextField {...params} label='Salesforce Label' />}
                                                        onChange={(e, property) => setSalesforceProp({ ...salesforceProp, [objectType]: property })}
                                                    />
                                                </td>
                                                <td>
                                                    <Autocomplete
                                                        disablePortal
                                                        sx={{ width: 300 }}
                                                        options={hubspotLabel}
                                                        id={`hubspotLabel_${objectType}`}
                                                        // value={hubspotProp?.[objectType]?.['label'] || ''}
                                                        // PaperComponent={({ children }) => <Paper>{children}</Paper>}
                                                        getOptionDisabled={option => {
                                                            let sfField = salesforceProp?.[objectType];
                                                            if (option['label'] == 'Add New') return false;
                                                            else if (sfField && sourceTypes[sfField['type']] && !sourceTypes[sfField['type']].find(({ type }) => option['type'] == type)) return true;
                                                            else return false;
                                                        }}
                                                        getOptionLabel={option => {
                                                            if (option['label'] == 'Add New') return option['label'];
                                                            else return `${option['label']} ${option['name']} ${option['type'] && (option['type'])}`;
                                                        }}
                                                        renderOption={(props, option) => {
                                                            const { key, ...optionProps } = props;
                                                            return (
                                                                <Box key={key} component='li' {...optionProps} style={{ 'fontSize': '14px' }}>
                                                                    <span> {option['label']} </span>
                                                                    {option['type'] &&
                                                                        <>
                                                                            &nbsp; - &nbsp;
                                                                            <span style={{ 'color': '#5C5C5C' }}> {option['name']} ({option['type']}) </span>
                                                                        </>
                                                                    }
                                                                </Box>
                                                            );
                                                        }}
                                                        renderInput={(params) => <TextField {...params} label='HubSpot Label' />}
                                                        onChange={(e, property) => openNewHubProperty(property, objectType, source)}
                                                    />
                                                </td>
                                                <td scope='row'><button className='edit-btn' onClick={() => saveNewField(objectType, source)}> <i className='fa fa-save fa-lg' style={{ color: 'grey' }} /> Save</button></td>
                                            </tr>
                                        }
                                        {objectFieldsMapping?.['mappedFields'] && objectFieldsMapping?.['mappedFields'].map(field => (
                                            <tr>
                                                <td>{field['sourceLabel']}</td>
                                                <td>{field['hubspotLabel']}</td>
                                                {field['hubspotStatus'] != 'default' ? 
                                                    <td scope='row'>
                                                        <button className='edit-btn' onClick={() => {
                                                            setAction('edit');
                                                            setEditField(field);
                                                            setNewModal(objectType);
                                                        }}>
                                                            <i className='icon'><img src={require('../../static/images/edit-icon.png')} alt='' /></i> Edit
                                                        </button>
                                                    </td>
                                                    : <td> Read Only </td>
                                                }
                                                <td scope='row'><button className='edit-btn' onClick={() => dispatch(removeField({ key: migration['key'], fieldId: field['_id'] }))}> <i className='icon'><img src={require('../../static/images/remove-btn.png')} alt='' /></i> Remove</button></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
            })}

            {/* ------------------New Property Modal----------------- */}
            <Modal
                right
                isOpen={newModal ? true : false}
                className='modal-payment label-modal right'
                aria-labelledby='contained-modal-title-vcenter'
            >
                <ModalHeader>
                    <button onClick={() => setNewModal('')}>X</button>
                </ModalHeader>
                <ModalBody style={{ 'overflow-y': 'scroll' }}>
                    <AddProperty
                        action={action}
                        source={source}
                        editField={editField}
                        setNewModal={setNewModal}
                        sourceTypes={sourceTypes}
                        salesforceProp={salesforceProp[newModal]}
                    />
                </ModalBody>
            </Modal>


            {/* ------------------Error Modal----------------- */}
            <Modal
                isOpen={errorMessage ? true : false}
                className='modal-payment'
                aria-labelledby='contained-modal-title-vcenter'
                centered>
                <ModalBody>
                    <div className='payment-box'>
                        <div className='title-area text-center'>
                            <h2>Mapping Error</h2>
                        </div>
                        <div className='pt-3'> {errorMessage} </div>
                        <div className='text-center pt-3'>
                            <button className='btn-style-one' onClick={() => dispatch(updateErrorPopup(''))}> Got it! </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

const objects = [
    { label: 'Opportunities', key: 'Opportunities_HardData' }, { label: 'Leads', key: 'Leads_HardData' }, { label: 'Contacts', key: 'Contacts_HardData' }, { label: 'Accounts', key: 'Accounts_HardData' },
    { label: 'Notes', key: 'Notes_SoftData' }, { label: 'Tasks', key: 'Tasks_SoftData' }, { label: 'Events', key: 'Events_SoftData' }, { label: 'Emails', key: 'Email-Message_SoftData' }, { label: 'Attachments', key: 'Attachments_SoftData' }
]

export default FieldMapping;