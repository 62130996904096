import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';

import './index.css';
import { login } from '../../store/actions/Auth';
import { sidebarActive } from "../../store/actions/sidebar";

class SidebarMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMount: false,
        };
    };

    render() {
        let { isOpen } = this.state;
        let { sidebarActive, activeSection } = this.props;
        return (
            <>
                <div className="migratr-sidebar">
                    <div className="sidebar-area">
                        <div className="logo">
                            <img src={require('../../static/images/logo.png')} alt='' />
                        </div>
                        <ul className="sidebar-menu">
                            <li>
                                <Link className={`nav-item ${activeSection == 'Dashboard' && 'active'}`} to="/Dashboard">
                                    <i className="icon">
                                        <img src={require('../../static/images/home-icon.png')} alt='' />
                                    </i>
                                    Dashboard
                                </Link>
                            </li>
                            <li>
                                <Link className={`nav-item ${activeSection == 'Settings' && 'active'}`} to="#">
                                    <i className="icon">
                                        <img src={require('../../static/images/setting-icon.png')} alt='' />
                                    </i>
                                    Settings
                                </Link>
                            </li>

                        </ul>
                        <div className="logo-bottom">
                            <img src={require('../../static/images/logo-singin.png')} alt='' />
                        </div>
                    </div>
                    <div className="sidebar-area mobile-sidebar-area">
                        <div className="logo">
                            <img src={require('../../static/images/login-icon.png')} alt='' />
                        </div>
                        <ul className="sidebar-menu">
                            <li>
                                <Link className={`nav-item ${activeSection == 'Dashboard' && 'active'}`} to="/Dashboard">
                                    <i className="icon">
                                        <img src={require('../../static/images/home-icon.png')} alt='' />
                                    </i>
                                </Link>
                            </li>
                            <li>
                                <Link className={`nav-item ${activeSection == 'Settings' && 'active'}`} to="#">
                                    <i className="icon">
                                        <img src={require('../../static/images/setting-icon.png')} alt='' />
                                    </i>
                                </Link>
                            </li>
                        </ul>
                        <div className="logo-bottom">
                            <img src={require('../../static/images/logo-singin-icon.png')} alt='' />
                        </div>
                    </div>
                </div >
                {/* <button className='navbar-toggler sidebar-nav-toggle' type='button'>
                    <i class='icon' aria-hidden='true'><img src={require('../../static/images/mobile-menu.png')} alt='' /></i>
                </button> */}
            </>
        );
    }
}

// const mapDispatchToProps = { sidebarActive
// };

// const mapStateToProps = ({ Auth, Sidebar }) => {
//     let { } = Auth ;
//     let {isActive} = Sidebar;
//     return {isActive}
// };

export default (SidebarMenu);