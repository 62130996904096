import axios from 'axios';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';
import { toggleLoader, signInData, setStep } from "../actions/Auth";

function* signIn({ payload }) {
  const { error, response } = yield call(postCall, { path: 'user/login', payload: payload || {} });
  if (error) EventBus.publish('error', error.response?.data?.message);
  else if (response) {
    yield put(setStep(2));
    EventBus.publish('success', response?.data?.message);
  }
}

function* resendCode({ payload }) {
  const { error, response } = yield call(postCall, { path: 'user/resendCode', payload: payload || {} });
  if (error) EventBus.publish('error', error.response?.data?.message);
  else if (response) EventBus.publish('success', response?.data?.message);
}

function* verifyCode({ payload }) {
  const { error, response } = yield call(postCall, { path: 'user/verify', payload: payload || {} });
  if (error) EventBus.publish('error', error.response?.data?.message);
  else if (response) {
    yield put(signInData(response.data.body));
    EventBus.publish('success', response?.data?.message);
  }
}

function* authHubspot({ payload }) {
  yield put(toggleLoader({ message: `Connecting to HubSpot...`, status: true }));
  const { error, response } = yield call(postCall, { path: 'migrations/oAuthHubSpot', payload: payload || {} });
  if (error) {
    EventBus.publish('error', error?.['response']?.['data']?.['message']);
    setTimeout(() => window.location.replace('/SignIn'), 2000);
  } else if (response) {
    // Authenticate User
    yield put(signInData(response['data']['body']));
    EventBus.publish('success', response?.['data']?.['message']);
  }
  yield put(toggleLoader({ message: ``, status: false }));
};

function* hubLogin({ payload }) {
  yield put(toggleLoader({ message: `Connecting to HubSpot...`, status: true }));
  const { error, response } = yield call(postCall, { path: 'migrations/hubLogin', payload: payload || {} });
  if (error) {
    EventBus.publish('error', error?.['response']?.['data']?.['message']);
    setTimeout(() => window.location.replace('/SignIn'), 2000);
  } else if (response) {
    // Authenticate User
    yield put(signInData(response['data']['body']));
    EventBus.publish('success', response?.['data']?.['message']);
  }
  yield put(toggleLoader({ message: ``, status: false }));
};

function* actionWatcher() {
  yield takeEvery('SIGN_IN', signIn);
  yield takeEvery('HUB_LOGIN', hubLogin);
  yield takeEvery('RESEND_CODE', resendCode);
  yield takeEvery('VERIFY_CODE', verifyCode);
  yield takeEvery('AUTH_HUBSPOT', authHubspot);
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    // .post(path, null, { params: payload })
    .then(response => ({ response }))
    .catch(error => {
      console.log('****** error = ', error);
      if (error?.response?.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      console.log('******* error = ', error);
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};