import React from 'react';
import { Snackbar, Alert, Grow } from '@mui/material';

const Notification = (props) => {
    const { open, setOpen, message, severity } = props;

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpen(false);
    };

    return (
        <div>
            <Snackbar
                open={open}
                anchorOrigin={{vertical: 'top', horizontal: 'right' }}
                autoHideDuration={5000}
                TransitionComponent={Grow}
                onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={severity}
                    variant='filled'
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default Notification;