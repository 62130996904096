export const signIn = (data) => ({
  payload: data,
  type: 'SIGN_IN',
});

export const resendCode = (data) => ({
  payload: data,
  type: 'RESEND_CODE',
});

export const verifyCode = (data) => ({
  payload: data,
  type: 'VERIFY_CODE',
});

export const signInData = (data) => ({
  payload: data,
  type: 'SIGN_IN_DATA',
});

export const authHubspot = (data) => ({
  payload: data,
  type: 'AUTH_HUBSPOT',
});

export const signOut = () => ({
  type: 'SIGN_OUT'
});

export const setStep = (data) => ({
  payload: data,
  type: 'SET_STEP',
});

export const toggleLoader = (data) => ({
  payload: data,
  type: 'TOGGLE_LOADER',
});

export const hubLogin = (data) => ({
  payload: data,
  type: 'HUB_LOGIN',
});