import { Button } from 'reactstrap';
import EventBus from 'eventing-bus';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import 'animate.css';
import './index.css';
import '../../static/css/animate.css';
import { signIn, resendCode, verifyCode, authHubspot, hubLogin } from "../../store/actions/Auth";

const SignIn = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    let { step } = useSelector(st => st['Auth']);

    let [code, setCode] = useState('');
    let [email, setEmail] = useState('');
    let [openMigratr, setOpenMigratr] = useState('');
    let [verifyLoading, setVerifyLoading] = useState(false);

    useEffect(() => {
        const split = location['pathname'].split('/');
        const type = split[split.length - 1], urlParams = new URLSearchParams(window['location']['search']);
        if(type == 'hubLogin') dispatch(hubLogin({ hash: urlParams.get('hash') }));
        if (type == 'hubRedirect') setOpenMigratr(`${window['location']['origin']}/hubLogin?hash=${urlParams.get('hash')}`);
        if (type == 'hubspotAuthenticate') dispatch(authHubspot({ platform: 'Hubspot', key: urlParams.get('state'), code: urlParams.get('code') }));
    }, []);

    const submitEmail = () => {
        if (email == '') return EventBus.publish('info', 'Please enter the Email');
        dispatch(signIn({ email }));

        setVerifyLoading(true);
        setTimeout(() => setVerifyLoading(false), 3000);
    };

    const submitCode = () => {
        if (!email) return EventBus.publish('info', 'Please provide Email');
        else if (!code) return EventBus.publish('info', 'Please enter the code');
        dispatch(verifyCode({ email, code }));
    };

    const clickResendCode = () => dispatch(resendCode({ email }));
    return (
        <div className='migratr-page'>
            <div className="singup-page">
                <div className='logo-top'>
                    <img src={require('../../static/images/logo-singin.png')} alt='' />
                </div>
                <div className='content'>
                    <div className='icon-area'>
                        <img src={require('../../static/images/login-icon.png')} alt='' />
                        <h2>Hi! Welcome to <span className='green-text'>Migratr</span></h2>
                    </div>
                    {openMigratr ?
                        <div className="signup-form text-center">
                            <a className='btn-style-one' href={openMigratr} target='_blank'> Open Migratr <i className='fa fa-external-link' /> </a>
                        </div>
                        :
                        <>
                            <div className="signup-form">
                                {step == 1 ?
                                    <ValidatorForm className="validatorForm">
                                        <Grid container spacing={1} className='group-input' alignItems="flex-end">
                                            <Grid className="signup-fields" item xs={12}>
                                                <label>Email Address</label>
                                                <CustomTextField
                                                    fullWidth
                                                    name="email"
                                                    type="email"
                                                    value={email}
                                                    margin="dense"
                                                    autoComplete='off'
                                                    validators={['required']}
                                                    placeholder="user@email.com"
                                                    errorMessages={['Email address can not be empty']}
                                                    onChange={((e) => setEmail(e.target.value))}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Button type="submit" disabled={verifyLoading} onClick={submitEmail}> Verify </Button>
                                    </ValidatorForm>
                                    :
                                    <>
                                        <h4>Check your email for a code</h4>
                                        <p>
                                            We've sent a 6-character code to {email}<br />
                                            {/* The code expires shortly, so please enter it soon. */}
                                        </p>
                                        <div className='lable-area'>
                                            <label>Type code here</label>
                                            <a className='resend-link' onClick={clickResendCode}>
                                                Re-send the code
                                                <div className='tooltip-icon'>
                                                    <img src={require('../../static/images/info-icon.png')} alt='' />
                                                    <div className='overlaybox'>
                                                        <p>Can't find your code? Check your spam folder!</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <ValidatorForm className="validatorForm">
                                            <Grid container spacing={1} className='group-input' alignItems="flex-end">
                                                <Grid className="signup-fields" item xs={12}>
                                                    <CustomTextField
                                                        fullWidth
                                                        name="code"
                                                        type="text"
                                                        value={code}
                                                        margin="dense"
                                                        autoComplete='off'
                                                        placeholder="000-000"
                                                        validators={['required']}
                                                        errorMessages={['The code is expired']}
                                                        onChange={((e) => setCode(e.target.value))}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Button type="submit" onClick={submitCode}> Submit Code </Button>
                                        </ValidatorForm>
                                    </>
                                }
                            </div>
                            <p className='forgetpass'>By creating an account, you agree to our<br /><Link className="singin-btn" to="/ForgotPassword">Terms of Services and Privacy</Link> & <Link className="singin-btn" to="/ForgotPassword">Cookie Statement</Link> </p>
                        </>
                    }
                </div>
            </div>
        </div >
    );
};

const CustomTextField = withStyles({
    root: {
        '& .MuiInputBase-input': {
            color: '#fff', // Text color
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#fff', // Semi-transparent underline
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: '#fff', // Solid underline on hover
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#fa6634', // Solid underline on focus
        },
    },
    input: {
        '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
        }
    }
})(TextValidator);

export default SignIn;