import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { MenuItem, FormControl, Select } from '@mui/material';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './index.css';
import 'animate.css';
import '../../static/css/animate.css';
import 'react-circular-progressbar/dist/styles.css';

import Sidebar from '../../components/sidebar';
import NavbarInner from '../../components/navbar-inner';
import PaymentForm from '../../components/payment-form';
import PostPayment from '../../components/post-payment';
import Notification from '../../components/notification';
import FieldMapping from '../../components/field-mapping';
import { getMigration, getMigrations, setupFullMigration, newMigration, getResults, deleteObject, setDeleteMigration, restartObject, setMigration, downloadFile } from '../../store/actions/Migration';

const Dashboard = () => {
    const dispatch = useDispatch();

    const [activeSection] = useState();
    const [newModal, setNewModal] = useState(false);
    const [resultsPage, setResultsPage] = useState(1);
    const [deleteObj, setDeleteObj] = useState('');
    const [restartObj, setRestartObj] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [resultsSearch, setResultsSearch] = useState('');
    const [detailsModal, setDetailsModal] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);
    const [notification, setNotification] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState('unpaid');
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationSeverity, setNotificationSeverity] = useState('success');

    const [deleteMigration, setDelete] = useState({});
    const [objectName, setObjectName] = useState('companies');
    const { migration, migrations, hsAuth, sfAuth, results, resultPages, errors, stripeSecret, totalRecords } = useSelector(st => st.Migration);

    useEffect(() => { dispatch(getMigrations()) }, []);

    useEffect(() => {
        let migrationObj = migration;
        migrationObj['paymentStatus'] = paymentStatus;
        setMigration(migration);
    }, [paymentStatus]);

    const createMigration = () => { dispatch(newMigration(companyName)) };
    const updateResultsPage = value => { setResultsPage(parseInt(value)) };
    const setResults = (migration, objectName, search = '', page = 1) => { dispatch(getResults({ key: migration['key'], objectName, search, page })) };

    const Capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);
    const singularNames = { deals: 'deal', contacts: 'contact', companies: 'company' };

    const stripePromise = loadStripe(process['env']['REACT_APP_STRIPE_PUB_KEY']);
    const stripePISecret = new URLSearchParams(window['location']['search']).get('payment_intent_client_secret');

    const detailsPopup = (migration) => {
        setDetailsModal(true);
        dispatch(getMigration({ key: migration['key'], type: 'none' }));
    }

    return (
        <div className='dashboard-page active'>
            <Notification open={notification} setOpen={setNotification} message={notificationMessage} severity={notificationSeverity} />
            {stripePISecret &&
                <Elements stripe={stripePromise}>
                    <PostPayment
                        modalSetter={setDetailsModal}
                        migrationGetter={getMigration}
                        notificationSetter={setNotification}
                        fullMigrationSetter={setPaymentStatus}
                        notificationMessageSetter={setNotificationMessage}
                        notificationSeveritySetter={setNotificationSeverity}
                    />
                </Elements>
            }
            <Sidebar activeSection={activeSection} />
            <VisibilitySensor>
                <div className='content'>
                    <NavbarInner />
                    <div className='dashboard-area'>
                        <div className='title-area'>
                            <h2>My Dashboard</h2>
                            <a className='btn-style-one' onClick={() => setNewModal(true)}>New Migration</a>
                        </div>
                        {migrations.length == 0 ?
                            <div className='content-box'>
                                <h3>You have not started any migration projects yet.</h3>
                                <p>We're ready to move some data when you are! <i className='icon'><img src={require('../../static/images/duck.png')} alt='' /></i></p>
                            </div>
                            :
                            <div className='content-box'>
                                {/* after migration show this div */}
                                <div className='table-area checkout-table'>
                                    <div class='table-responsive'>
                                        <table class='table'>
                                            <thead>
                                                <tr>
                                                    <th>Status</th>
                                                    <th>Name</th>
                                                    <th>From</th>
                                                    <th>To</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {migrations.map(migration => {
                                                    let status = 'In-Progress';
                                                    if (['created', 'connected', 'field-mapping'].includes(migration['status'])) status = 'Drafted';
                                                    else if (['sample', 'activated', 'in-progress'].includes(migration['status']) && migration['inQueueTasks'] > 0) status = 'In-Progress';
                                                    else if (['sample', 'activated'].includes(migration['status']) && migration['inQueueTasks'] == 0) status = 'Stopped';
                                                    else if (['completed', 'in-progress'].includes(migration['status']) && migration['inQueueTasks'] == 0) status = 'Completed';
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <div className='company-data'>
                                                                    <i className={`icon-circle ${status}`}></i> {status}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='company-data'>{migration['companyName']}</div>
                                                                {!['created', 'connected', 'field-mapping'].includes(migration['status']) &&
                                                                    <button className='without-icon' onClick={() => detailsPopup(migration)}>See details</button>
                                                                }
                                                                {/* <div className='company-data'> <h4>{migration['key'].split('-')[0]}</h4> </div> */}
                                                            </td>
                                                            <td>
                                                                <div className='company-data'>
                                                                    <i className='icon'><img src={require('../../static/images/salesforce.jpg')} alt='' /></i> Salesforce
                                                                </div>
                                                                {migration['sourceAccountId'] ?
                                                                    <Link to='#' style={{ cursor: 'default' }} >{migration?.['sourceAccountId']}</Link>
                                                                    : <a href={`${sfAuth}&state=${migration['key']}`}>Connect</a>
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className='company-data'>
                                                                    <i className='icon'><img src={require('../../static/images/hubspot.jpg')} alt='' /></i> HubSpot
                                                                </div>
                                                                {migration['hubspotPortalId'] ?
                                                                    <Link to='#' style={{ cursor: 'default' }} >{migration['hubspotPortalId']}</Link>
                                                                    : <a href={`${hsAuth}&state=${migration['key']}`}>Connect</a>
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    status == 'Drafted' ?
                                                                        <div>
                                                                            <Link class='ml-3' to={`/NewMigration?key=${migration['key']}`}> <i className='fa fa-edit' style={{ 'fontSize': '20px' }} /> </Link>
                                                                            <Link class='ml-4' to='#' onClick={() => setDelete(migration)}> <i className='fa fa-trash' style={{ 'fontSize': '20px' }} /> </Link>
                                                                        </div>
                                                                        : migration['paymentStatus'] == 'paid' && migration['status'] == 'sample' ? <button className='without-icon' onClick={() => dispatch(setupFullMigration(migration['key']))}> Run full migration </button>
                                                                            : migration['paymentStatus'] == 'unpaid' && migration['inQueueTasks'] == 0 ? 'Purchase full migration'
                                                                                // : migration['paymentStatus'] == 'unpaid' && migration['inQueueTasks'] == 0 ? <button className='without-icon' onClick={() => setPaymentModal(true)}> Purchase full migration </button>
                                                                                // : migration['status'] == 'in-progress' && migration['inQueueTasks'] == 0 ? <button className='without-icon' onClick={() => dispatch(createRelations(migration['key']))}> Run Object Relations </button>
                                                                                : migration['status'] === 'sample' ? 'Running Sample'
                                                                                    : ['in-progress'].includes(migration['status']) && migration['inQueueTasks'] > 0 ? 'Running Full Migration'
                                                                                        : ['completed', 'in-progress'].includes(migration['status']) && migration['inQueueTasks'] == 0 ? 'Migration Completed'
                                                                                            : <a className='btn-style-two' onClick={() => detailsPopup(migration)}>Details</a>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div >
            </VisibilitySensor>
            {/* ------------------New Migration Modal----------------- */}
            <Modal
                isOpen={newModal}
                className='modal-payment'
                aria-labelledby='contained-modal-title-vcenter'
                centered>
                <ModalBody>
                    <div className='payment-box'>
                        <div className='title-area text-center mb-4'>
                            <h2>Start New Migration</h2>
                        </div>
                        <ValidatorForm className='validatorForm'>
                            <Grid container className='group-input' alignItems='flex-end'>
                                <Grid item xs={12}>
                                    <label className='mb-0'>Migration Name*</label>
                                    <TextValidator
                                        fullWidth
                                        type='text'
                                        margin='dense'
                                        name='companyName'
                                        autoComplete='off'
                                        value={companyName}
                                        validators={['required']}
                                        placeholder='Migration 001'
                                        errorMessages={['Name can not be empty']}
                                        onChange={(e => setCompanyName(e['target']['value']))}
                                    />
                                </Grid>
                            </Grid>
                            <div className='text-center pt-3'>
                                <button className='mx-5 btn-style-two' onClick={() => setNewModal(false)}> Close </button>
                                <button className='mx-5 btn-style-one' type='submit' onClick={createMigration}> Next </button>
                            </div>
                        </ValidatorForm>
                    </div>
                </ModalBody>
            </Modal>

            {/* ------------------Migration Detail MODAL----------------- */}
            <Modal
                isOpen={detailsModal}
                className='modal-migrationDetail'
                aria-labelledby='contained-modal-title-vcenter'
                centered>
                <ModalHeader toggle={() => setDetailsModal(!detailsModal)} style={{ zIndex: 100000000000000000000000000000 }}>
                    <span className='text-center'> <b>{migration['companyName']} </b> | ID: {migration['key']?.split('-')[migration['key']?.split('-').length - 1]}</span>
                    <i className='pl-3 fa fa-refresh' style={{ cursor: 'pointer' }} onClick={() => dispatch(getMigration({ key: migration['key'], type: 'none' }))} />
                    {migration['status'] == 'sample' && <span className='sample-tag'>Sample Migration*</span>}
                    <p style={{ 'fontSize': '12px' }}>
                        {migration['createdAt'] && <>Created At: <strong>{moment(migration['createdAt']).format('lll')}</strong></>}
                        {migration['completedAt'] && <> &nbsp; Completed At: <strong>{moment(migration['completedAt']).format('lll')}</strong></>}
                    </p>
                </ModalHeader>
                <ModalBody className='modal-body'>
                    <Tabs>
                        <div className='top-tabs-area'>
                            <div className='left-area'>
                                <TabList>
                                    <Tab>Summary</Tab>
                                    <Tab>Results</Tab>
                                    <Tab>Errors</Tab>
                                    <Tab>Settings</Tab>
                                </TabList>
                            </div>
                            <div className='right-area'>
                                {migration['paymentStatus'] == 'paid' && migration['status'] == 'sample' ? <button className='btn-style-one' onClick={() => dispatch(setupFullMigration(migration['key']))}> Run full migration </button>
                                    : migration['paymentStatus'] != 'paid' && migration['inQueueTasks'] == 0 ? <button className='btn-style-one' onClick={() => setPaymentModal(true)}> Purchase full migration </button>
                                        // : migration['status'] == 'in-progress' && migration['inQueueTasks'] == 0 ? <button className='btn-style-one' onClick={() => dispatch(createRelations(migration['key']))}> Run Object Relations </button> 
                                        : null
                                }
                            </div>
                        </div>
                        <div className='main-content-tabs'>
                            {/* Summary tab */}
                            <TabPanel>
                                <div className='summry-tab'>
                                    <div className='result-tabs-area'>
                                        <div className='record-area'>
                                            <div className='record-box'>
                                                <p>Migrated Records</p>
                                                <h2>{migration?.['migratedCount'] || 0}</h2>
                                            </div>
                                            <div className='record-box'>
                                                <p>In-Queue Tasks</p>
                                                <h2>{migration['inQueueTasks'] || 0}</h2>
                                            </div>
                                            <div className='record-box'>
                                                <p>Errors Found</p>
                                                <h2>{migration?.['totalErrors'] || 0}</h2>
                                            </div>
                                            <div className='record-box'>
                                                <p>
                                                    Total Records
                                                    <i className='icon-info'><img src={require('../../static/images/info-icon.png')} alt='' />
                                                        <div className='overlabox-tooltip'> <p>These are total number of record counted in salesforce. </p> </div>
                                                    </i>
                                                </p>
                                                <h2>{migration?.['totalRecords'] || 0}</h2>
                                            </div>
                                        </div>
                                        <div className='table-summry'>
                                            <div class='table-responsive'>
                                                <table class='table'>
                                                    <tbody>
                                                        {migration['objects'] && Object.values(migration['objects']).map(row => {
                                                            return (
                                                                <tr>
                                                                    {row.map(object => {
                                                                        let insertion = object['hsObject'] || {};
                                                                        let extractCount = (object['noOfRecord'] && object['count'] > object['noOfRecord']) ? object['noOfRecord'] : (object['count'] || 0);
                                                                        let insertCount = (insertion['noOfRecord'] && insertion['count'] > insertion['noOfRecord']) ? insertion['noOfRecord'] : (insertion['count'] || 0);
                                                                        let percent = (parseFloat((object['migratedCount'] + (insertion?.['migratedCount'] || 0)) / (object['count'] + insertCount) * 100).toFixed(0)) || 0;
                                                                        if (isNaN(percent)) percent = 0;

                                                                        // Associations status
                                                                        let associationStatus;
                                                                        if (object['hsAssociations'] && object['hsAssociations'].length > 0)
                                                                            for (let association of object['hsAssociations'])
                                                                                if (!associationStatus || associationStatus == 'Completed') associationStatus = association['status'];

                                                                        // console.log(`********* ${object['label']} object = `, object);
                                                                        return (
                                                                            <td>
                                                                                <div className='download-data'>
                                                                                    <div className='progress-bar-circle'>
                                                                                        <div className='progress-inner' style={{ width: 36, height: 36 }}>
                                                                                            <CircularProgressbar
                                                                                                value={percent}
                                                                                                strokeWidth={50}
                                                                                                styles={buildStyles({ strokeLinecap: 'butt' })}
                                                                                            />
                                                                                            {object['status'] == 'In-Progress' && <p>{percent}%</p>}
                                                                                            {object['status'] == 'Error' && <p><img src={require('../../static/images/error-icon.png')} alt='' /></p>}
                                                                                            {object['status'] == 'Completed' && <p><img src={require('../../static/images/check-icon.png')} alt='' /></p>}
                                                                                        </div>
                                                                                        <div className='right-area'>
                                                                                            <h5>
                                                                                                {_.capitalize(object['label'])}
                                                                                                {object['label'] == 'tasks' &&
                                                                                                    <i className='icon-info'><img src={require('../../static/images/info-icon.png')} alt='' />
                                                                                                        <div className='overlabox-tooltip'> <p>These are which has subtype as Task in Salesforce. </p> </div>
                                                                                                    </i>
                                                                                                }
                                                                                            </h5>
                                                                                            {object['status'] == 'In-Queue'
                                                                                                ? <p>SF ...Pending</p>
                                                                                                : <p>
                                                                                                    SF Exporting {(object['migratedCount'] > extractCount ? extractCount : object['migratedCount']) || 0} {` of `} {extractCount}
                                                                                                    {object['errors']?.length > 0 && <>&nbsp;({object['errors'].length} {`Error${object['errors'].length > 1 ? 's' : ''}`}) <i class='fa fa-exclamation-triangle' style={{ 'color': 'red' }} /></>}
                                                                                                </p>
                                                                                            }
                                                                                            {insertion['status'] && <> {insertion['status'] == 'In-Queue'
                                                                                                ? <p>HS ...Pending</p>
                                                                                                : <p>
                                                                                                    HS Importing {(insertion['migratedCount'] > insertCount ? insertCount : insertion['migratedCount']) || 0} {` of `} {insertCount}
                                                                                                    {insertion?.['errors']?.length > 0 && <>&nbsp;({insertion['errors'].length} {` Error${insertion['errors'].length > 1 ? 's' : ''}`}) <i class='fa fa-exclamation-triangle' style={{ 'color': 'red' }} /></>}
                                                                                                </p>
                                                                                            }</>}
                                                                                            {associationStatus == 'In-Progress' ? <p> Association In Progress </p>
                                                                                                : associationStatus == 'Completed' ? <p> Associated </p>
                                                                                                    : associationStatus == 'Error' ? <p> Association Error <i class='fa fa-exclamation-triangle' style={{ 'color': 'red' }} /></p> : null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='right-btn'>
                                                                                        {(object['errors']?.length > 0 || insertion['errors']?.length > 0 || ((migration['inQueueTasks'] == 0 || !migration['inQueueTasks']) && (insertion['migratedCount'] < insertCount || object['migratedCount'] < extractCount))) &&
                                                                                            <button className='download-btn' onClick={() => setRestartObj(object)}><i className='fa fa-refresh' style={{ 'color': '#0065ff' }} /></button>
                                                                                        }
                                                                                        {percent == 100 && <button className='download-btn' onClick={() => dispatch(downloadFile({ key: migration['key'], objectType: object['key'] }))}><img src={require('../../static/images/download-btn.png')} alt='' /></button>}
                                                                                        {percent > 0 && object['key'].indexOf('user') < 0 && <button className='download-btn' onClick={() => setDeleteObj(object)}><i className='fa fa-trash'></i></button>}
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        )
                                                                    })}
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            {/* Results tab */}
                            <TabPanel>
                                <div className='summry-tab'>
                                    <div className='result-tabs-area'>
                                        <div className='top-form-area'>
                                            <h4>{totalRecords || 0} Migrated Records</h4>
                                            <div className='form-box'>
                                                <div className='group-form'>
                                                    <FormControl sx={{ minWidth: 399 }}>
                                                        <label className='pb-2'>Objects</label>
                                                        <Select
                                                            id='supported-objects-multiple-checkbox'
                                                            value={objectName}
                                                            onChange={({ target }) => {
                                                                setObjectName(target['value'])
                                                                setResults(migration, target['value'], resultsSearch, 1)
                                                            }}
                                                        >
                                                            <MenuItem value={'companies'}> Companies </MenuItem>
                                                            <MenuItem value={'contacts'}> Contacts </MenuItem>
                                                            <MenuItem value={'deals'}> Deals </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div className='group-form'>
                                                    <label className='pb-2'>Search</label>
                                                    <input type='text' value={resultsSearch} onChange={({ target }) => { setResultsSearch(target['value']) }} placeholder='Salesforce ID, HubSpot ID, Name' />
                                                </div>
                                                <button className='btn-style-one' onClick={() => { setResults(migration, objectName, resultsSearch, 1) }}>Find</button>
                                            </div>
                                        </div>
                                        <p className={(results?.length > 0 ? 'hide' : 'display-block ml-3 pl-4')}>There are no {objectName} to show.</p>
                                        {results?.length > 0 &&
                                            <div className='table-result'>
                                                <div class='table-responsive'>
                                                    <table class='table'>
                                                        <tbody>
                                                            {results?.map(result => {
                                                                return (
                                                                    <tr>
                                                                        <td> {Capitalize(singularNames[objectName])} Name: {result?.['data']?.['Name']}  </td>
                                                                        <td> <a className='open-btn' href={`${migration.sourceExtra.instanceUrl}/${result.data.Id}`} target='_blank' rel='noreferrer'>Open in Salesforce</a> </td>
                                                                        <td> <a className='open-btn' href={`https://app.hubspot.com/contacts/${migration['hubspotPortalId']}/${singularNames[objectName]}/${result['hubspotId']}`} target='_blank' rel='noreferrer'>Open in HubSpot</a> </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
                                        {/* Results table paginator */}
                                        {resultPages?.length > 1 &&
                                            <div className='table-paginator'>
                                                <button value={resultsPage - 1} disabled={resultsPage === 1} className={'open-btn ' + (resultsPage === 1 ? 'black bold' : '')}
                                                    onClick={({ target }) => {
                                                        updateResultsPage(target['value']);
                                                        setResults(migration, objectName, resultsSearch, target['value']);
                                                    }}>Previous</button>

                                                {resultPages?.map((data, index) => (
                                                    <button disabled={index + 1 === resultsPage} className={'open-btn ' + (index + 1 === resultsPage ? 'black bold' : '')} value={index + 1}
                                                        onClick={({ target }) => { updateResultsPage(target['value']); setResults(migration, objectName, resultsSearch, target['value']); }}>{index + 1}
                                                    </button>
                                                ))}

                                                <button value={parseInt(resultsPage) + 1} disabled={resultsPage === resultPages.length} className={'open-btn ' + (resultsPage === resultPages.length ? 'black bold' : '')}
                                                    onClick={({ target }) => {
                                                        updateResultsPage(target['value']);
                                                        setResults(migration, objectName, resultsSearch, target['value']);
                                                    }}>Next</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </TabPanel>
                            {/* Errors tab */}
                            <TabPanel>
                                <div className='summry-tab'>
                                    <div className='result-tabs-area'>
                                        <div className='csv-download'><DownloadErrorsCSV data={errors} fileName='errors' /></div>
                                        {/* <div className='top-form-area'>
                                            <h4>3 data validations errors</h4>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod <Link to='#'>tempor incididunt ut labore et dolore magna aliqua.</Link></p>
                                        </div> */}
                                        {errors?.extraction?.length > 0 &&
                                            <div className='extraction-errors errors-area'>
                                                <h4>Extraction errors</h4>
                                                {errors?.extraction.map(({ errors, _id }) => {
                                                    return (
                                                        <div className='errors-box'>
                                                            <h5><span>{errors.length} error{errors.length > 1 ? 's' : ''} </span>from {_id}</h5>
                                                            <table class='table'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Error</th>
                                                                        <th>Count</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>{errors.length}</td>
                                                                        <td>{(errors[0]['error']['body']?.['message'] || errors[0]['error']?.['message']).substring(0, 120).concat('...')}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        }
                                        {errors?.insertion?.length > 0 &&
                                            <div className='insertion-errors errors-area'>
                                                <h4>Insertion errors</h4>
                                                {errors?.insertion.map(({ errors, _id }) => {
                                                    return (
                                                        <div className='errors-box'>
                                                            <h5><span>{errors.length} error{errors.length > 1 ? 's' : ''} </span>from {_id}</h5>
                                                            <table class='table'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Error</th>
                                                                        <th>Count</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>{errors.length}</td>
                                                                        <td>{(errors[0]['error']['body']?.['message'] || errors[0]['error']?.['message']).substring(0, 120).concat('...')}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </TabPanel>
                            {/* Settings tab */}
                            <TabPanel className='dashboard-page'>
                                <div className='summry-tab content'>
                                    <div className='result-tabs-area dashboard-area'>
                                        <div className='content-box'>
                                            <FieldMapping />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                        {migration['status'] == 'sample' && <div className='bottom-note'>This is a sample migration we will migratr only 100 reords of each object, for the full migration click on Purchase full migration!</div>}
                    </Tabs>
                </ModalBody>
            </Modal>

            {/* ------------------Payment Modal----------------- */}
            <Modal
                isOpen={paymentModal}
                className='modal-payment'
                aria-labelledby='contained-modal-title-vcenter'
                centered>
                <ModalBody>
                    <div className='payment-box'>
                        <Elements stripe={stripePromise} options={{
                            clientSecret: stripeSecret?.['secret'],
                            // Fully customizable with appearance API.
                            appearance: {/*...*/ },
                        }}>
                            <PaymentForm migrationKey={migration['key']} amount={stripeSecret?.['amount']} />
                        </Elements>
                    </div>
                </ModalBody>
            </Modal>

            {/* ------------------Delete Migration Modal----------------- */}
            <Modal
                isOpen={deleteMigration['key']}
                className='modal-payment'
                aria-labelledby='contained-modal-title-vcenter'
                centered>
                <ModalBody>
                    <div className='payment-box'>
                        <div className='title-area text-center'> <h5>Delete Migration</h5> </div>
                        <p className='pt-2' style={{ 'fontSize': '17px' }}> Are you sure you want to delete {deleteMigration['companyName']} migration? </p>
                        <div className='text-center pt-3'>
                            <button className='mx-5 btn-style-two' onClick={() => setDelete({})}> No </button>
                            <button className='btn-style-one' onClick={() => { dispatch(setDeleteMigration(deleteMigration['key'])); setDelete({}) }}> Yes </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* ------------------Restart Object----------------- */}
            <Modal
                centered
                isOpen={restartObj['label']}
                className='modal-payment'
                aria-labelledby='contained-modal-title-vcenter'
            >
                <ModalBody>
                    <div className='payment-box'>
                        <div className='title-area text-center'> <h5>Restart Object</h5> </div>
                        <p className='pt-2' style={{ 'fontSize': '17px' }}> Are you sure you want to restart {_.capitalize(restartObj['label'])} object? </p>
                        <div className='text-center pt-3'>
                            <button className='mx-5 btn-style-two' onClick={() => setRestartObj('')}> No </button>
                            <button className='btn-style-one' onClick={() => { dispatch(restartObject(restartObj)); setRestartObj(''); }}> Yes </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* ------------------Delete Inserted Objects----------------- */}
            <Modal
                centered
                isOpen={deleteObj['label']}
                className='modal-payment'
                aria-labelledby='contained-modal-title-vcenter'
            >
                <ModalBody>
                    <div className='payment-box'>
                        <div className='title-area text-center'> <h5>Delete Inserted {deleteObj['label']}</h5> </div>
                        <p className='pt-2' style={{ 'fontSize': '17px' }}> Are you sure you want to delete {_.capitalize(deleteObj['label'])} object? </p>
                        <div className='text-center pt-3'>
                            <button className='mx-5 btn-style-two' onClick={() => setDeleteObj('')}> No </button>
                            <button className='btn-style-one' onClick={() => { dispatch(deleteObject(deleteObj)); setDeleteObj(''); }}> Yes </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div >
    );
};

const DownloadErrorsCSV = ({ data, fileName }) => {
    const convertToCSV = (objArray) => {
        let updateErrorsData = [];
        if (objArray.extraction) {
            for (let error of objArray.extraction) {
                const errorData = {
                    stage: 'extraction',
                    object: error['_id'],
                    count: error['errors'].length,
                    message: error.errors[0]?.['error']?.['message'],
                };
                updateErrorsData.push(errorData);
            }
        }
        if (objArray.insertion) {
            for (let error of objArray.insertion) {
                const errorData = {
                    stage: 'insertion',
                    object: error['_id'],
                    count: error['errors'].length,
                    message: error['errors'][0]?.['error']?.['message'],
                };
                updateErrorsData.push(errorData);
            }
        }
        objArray = updateErrorsData;
        let str = 'Count,Object,Message,Stage\n';
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line !== '') line += ',';
                line += array[i][index];
            }
            str += line + '\r\n';
        }
        return str;
    };

    const downloadCSV = () => {
        const csvData = new Blob([convertToCSV(data)], { type: 'text/csv' });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        link.download = `${fileName}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (<button class='btn-style-two' onClick={downloadCSV}>Download as CSV</button>);
};

export default Dashboard;