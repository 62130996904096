export const newMigration = (data) => ({
    payload: data,
    type: 'NEW_MIGRATION'
});

export const getMigration = (data) => ({
    payload: data,
    type: 'GET_MIGRATION',
});

export const setMigration = (data) => ({
    payload: data,
    type: 'SET_MIGRATION'
});

export const getMigrations = () => ({
    type: 'GET_MIGRATIONS'
});

export const setMigrations = (data) => ({
    payload: data,
    type: 'SET_MIGRATIONS',
});

export const setModalObj = (data) => ({
    payload: data,
    type: 'SET_MODAL_OBJ',
});


export const connectPlatform = (data) => ({
    payload: data,
    type: 'CONNECT_PLATFORM',
});

export const setSelectedObjects = (data) => ({
    payload: data,
    type: 'SET_SELECTED_OBJECTS',
});

export const setSupportedObjects = (data) => ({
    payload: data,
    type: 'SET_SUPPORTED_OBJECTS',
});

export const downloadCSV = (data) => ({
    payload: data,
    type: 'DOWNLOAD_CSV',
});

export const setDownloadCSV = (data) => ({
    payload: data,
    type: 'SET_DOWNLOAD_CSV',
});

export const restartObject = (data) => ({
    payload: data,
    type: 'RESERT_OBJECT',
});

export const uploadFields = (data) => ({
    payload: data,
    type: 'UPLOAD_FIELDS',
});

export const getFieldsMapping = (data) => ({
    payload: data,
    type: 'GET_FIELDS_MAPPING'
});

export const setFieldsMapping = (data) => ({
    payload: data,
    type: 'SET_FIELDS_MAPPING'
});

export const setDeleteMigration = (data) => ({
    payload: data,
    type: 'SET_DELETE_MIGRATION'
});

export const removeField = (data) => ({
    payload: data,
    type: 'REMOVE_FIELD'
});

export const nextFieldMapping = (data) => ({
    payload: data,
    type: 'NEXT_FIELD_MAPPING'
});

export const setupSample = (data) => ({
    payload: data,
    type: 'SETUP_SAMPLE'
});

export const setupFullMigration = (data) => ({
    payload: data,
    type: 'SETUP_FULL_MIGRATION'
});

export const getResults = (data) => ({
    payload: data,
    type: 'GET_RESULTS'
});

export const setResults = (data) => ({
    payload: data,
    type: 'SET_RESULTS'
});

export const setResultPages = (data) => ({
    payload: data,
    type: 'SET_RESULT_PAGES'
});

export const setResultTotal = (data) => ({
    payload: data,
    type: 'SET_RESULT_TOTAL'
});

export const getErrors = (data) => ({
    payload: data,
    type: 'GET_ERRORS'
});

export const setErrors = (data) => ({
    payload: data,
    type: 'SET_ERRORS'
});

export const getStripeSecret = (data) => ({
    payload: data,
    type: 'GET_STRIPE_SECRET'
});

export const setStripeSecret = (data) => ({
    payload: data,
    type: 'SET_STRIPE_SECRET'
});

export const createRelations = (data) => ({
    payload: data,
    type: 'CREATE_RELATIONS'
});

export const deleteObject = (data) => ({
    payload: data,
    type: 'DELETE_OBJECT'
});

export const saveNewMapping = (data) => ({
    payload: data,
    type: 'SAVE_NEW_MAPPING'
});

export const addHubspotProperty = (data) => ({
    payload: data,
    type: 'ADD_HUBSPOT_PROPERTY'
});

export const updateErrorPopup = (data) => ({
    payload: data,
    type: 'UPDATE_ERROR_POPUP'
});

export const downloadFile = (data) => ({
    payload: data,
    type: 'DOWNLOAD_FILE'
});