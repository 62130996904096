import { setToken } from "../axios";

let initialState = {
  step: 1,
  auth: localStorage.getItem('auth'),
  email: localStorage.getItem('email'),
  setLoader: { message: 'Please Wait...', status: false },
};

const Auth = (state = initialState, { type, payload }) => {

  switch (type) {

    case 'TOGGLE_LOADER':
      return {
        ...state,
        setLoader: payload,
      };

    case 'SIGN_IN':
      return {
        ...state,
        email: payload['email'],
      };

    case 'SET_STEP':
      return {
        ...state,
        step: payload,
      };

    case 'SIGN_IN_DATA':
      setToken(payload.token);
      localStorage.setItem('auth', payload.token);
      localStorage.setItem('email', payload.email);
      window.location.replace('/Dashboard');
      return {
        ...state,
        auth: payload.token
      };

    case 'SIGN_OUT':
      localStorage.removeItem('auth');
      localStorage.removeItem('email');
      window.location.replace('/');
      return initialState;

    default:
      return state;
  }
};

export default Auth;