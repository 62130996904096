import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import './index.css';
import { signOut } from "../../store/actions/Auth";

const Navbar = () => {
    const dispatch = useDispatch();
    const logout = () => dispatch(signOut());
    const { email } = useSelector(st => st['Auth']);

    return (
        <div className='navbar-main'>
            <nav className='navbar navbar-expand-lg sidenav' id="sidenav-1" data-mdb-hidden="false">
                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div className='user-img'>
                            <img src={require('../../static/images/user-img.png')} alt='' />
                        </div>
                        {email || 'Profile'}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" onClick={logout}> Logout </a>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Navbar;